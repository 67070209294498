import { Grid } from '@amzn/awsui-components-react';
import { ReactNode } from 'react';

export const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <Grid
            className="main"
            gridDefinition={[
                { colspan: { default: 0, m: 2 } },
                { colspan: { default: 12, m: 8 } },
                { colspan: { default: 0, m: 2 } },
            ]}
            disableGutters
        >
            <span />
            {children}
            <span />
        </Grid>
    );
};
