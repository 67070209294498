import { useEffect, useMemo, useState } from 'react';
import { useConfig } from '../contexts/ConfigContext';
import { ConfirmedAccount } from '../lib/aoc';
import { request } from '../lib/request';
import { extractInfoFromToken } from '../lib/tokenExtraction';
import { trimUrlForRedirect } from '../lib/urlUtil';

export type CodeAuthorizationState = {
    loading: boolean;
    confirmedAccount: ConfirmedAccount | null;
    err: string | Error | null;
};

export const useCodeAuthorization = (): CodeAuthorizationState => {
    const config = useConfig();
    const [loading, setLoading] = useState<boolean>(true);
    const [confirmedAccount, setConfirmedAccount] = useState<ConfirmedAccount | null>(null);
    const [err, setErr] = useState<string | Error | null>(null);

    const code = useMemo(() => {
        return new URLSearchParams(window.location.search).get('code');
    }, []);

    const params = new URLSearchParams(window.location.search);

    if (code) {
        params.delete('code');
        window.history.replaceState({}, window.location.href.split('?')[0], '?' + params.toString());
    }

    useEffect(() => {
        if (code) {
            setLoading(true);
            const params = new URLSearchParams();
            params.append('client_id', config.clientID);
            params.append('grant_type', 'authorization_code');
            params.append('code', code);
            params.append('redirect_uri', `${trimUrlForRedirect(window.location.href)}`);
            request(`${config.gandalfCognitoEndpoint}/oauth2/token`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                method: 'POST',
                body: params,
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error('Error during authroization');
                    }
                })
                .then((data) => {
                    setConfirmedAccount({
                        token: data.id_token,
                        refreshToken: data.refresh_token,
                        ...extractInfoFromToken(data.id_token),
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setErr(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [code, config]);

    return {
        loading,
        confirmedAccount,
        err,
    };
};
