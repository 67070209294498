import { TextContent } from '@amzn/awsui-components-react';
import React, { Component, ErrorInfo } from 'react';

export class ErrorBoundary extends Component<{
    FallbackComponent?: React.ElementType;
    children: React.ReactNode;
}> {
    public state = {
        hasError: false,
        error: null,
    };

    static getDerivedStateFromError(error: Error | null) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.log('ErrorBoundary', error, errorInfo);
        if (this.props.FallbackComponent) return;
    }

    render() {
        if (!this.state.hasError) return this.props.children;

        const FallbackComponent = this.props.FallbackComponent;
        return FallbackComponent ? (
            <FallbackComponent details={this.state.error && (this.state.error as any).toString()} />
        ) : (
            <TextContent>Something went wrong, please reload</TextContent>
        );
    }
}
